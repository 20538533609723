<template>
  <div class="navbar">
    <ul>
      <li>
        <router-link to="/">
          <img
            width="120"
            :src="require('@/assets/images/' + logo)"
            alt="shelter logo"
          />
        </router-link>
      </li>
      <li>
        <slot></slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Header",
  computed: {
    logo() {
      if (window.innerWidth > 767.98) {
        return "smart_logo_blue.png";
      } else {
        return "smart_logo_blue.png";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  font-family: "Gotham-Book";
  background: #fff;
}

.navbar ul {
  padding: 0 50px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.navbar ul li {
  list-style: none;
}

@media (max-width: 767.98px) {
  .navbar ul {
    padding: 0;
    width: 90%;
    margin: 0 auto;
  }
}
</style>
