import client from './requestclient';


const authentication = {
    // getUsers: () => {
    //     return new Promise((resolve, reject) => {
    //         client.then(data => {
    //             resolve(data);
    //         })
    //         .catch(error => {
    //             reject(error)
    //         })
    //     })
    // }

    // OR

    registerUser: async (body) => {
        return await client({URL_PATH: "registerrealtor", method: "POST", body: body});
    },

    loginUser: async(body) => {
        return await client({URL_PATH: "loginrealtor", method: "POST", body:body})
    },
    // resetPassword is only for a new realtor to create his password
    resetPassword: async(body) => {
        return await client({URL_PATH: "realtorresetpassword", method: "POST", body})
    },
    realtorForgotPassword: async(body) => {
        return await client({URL_PATH: "realtorforgotpassword", method: "POST", body})
    },
    realtorRecoverPassword: async(body) => {
        return await client({URL_PATH: "realtorrecoverpassword", method: "POST", body})
    }
}

export default authentication;