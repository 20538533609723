class Validators {
    constructor() {
        this.errors = "";
    }

    required(targetInput) {
        if(targetInput.length === 0) {
            this.errors += "Value is required. "
        }
    }

    validEmail(targetInput) {
        if(!targetInput.includes('@')) {
            this.errors += "must be a valid email."
        }
    }

    checkMinLength(targetInput, minLength) {
        if(targetInput.length <= minLength) {
            this.errors += `character length must be greater than ${minLength}. `;
        }
    }

    onlyLetters(targetInput) {
        let regex = /[0-9]/;
        if(regex.test(targetInput) === true) {
            this.errors += "value must not contain number. ";
        }
    }

    onlyNumbers(targetInput) {
        let regex = /\D/;
        if(regex.test(targetInput) === true) {
            this.errors += "value must contain only numbers";
        }
    }

    containNumber(targetInput) {
        let regex = /[0-9]/;
        if(regex.test(targetInput) === false) {
            this.errors += "value must contain a number";
        }
    }

    confirmPassword(password, confirmPassword) {
        if(password !== confirmPassword) {
            this.errors += "confirm password must match password";
        }
    }
}

export default Validators;